import React from 'react';
import './TopLinks.css';
import logoFb from '../assets/images/fb-logo.png'; // Use this image as a placeholder for all logos
import logoIg from '../assets/images/ig-logo.png'; // Use this image as a placeholder for all logos

const TopLinks = () => {
  return (
    <div className='top-links-container'>
      <div className='social-icons'>
        <a href='https://web.facebook.com/colegiofenixoficial' target='_blank' rel='noopener noreferrer'>
          <img src={logoFb} alt='Facebook' className='icon' />
        </a>
        <a href='https://www.instagram.com/colegiofenixchile/' target='_blank' rel='noopener noreferrer'>
          <img src={logoIg} alt='Instagram' className='icon' />
        </a>
      </div>
      {/* <div className='links'>
        <a href='#fono1' className='link'>+56 9 81893038</a>
        <a href='#fono2' className='link'>+56 9 81893021</a>
      </div> */}
    </div>
  );
};

export default TopLinks;

import React, { useState } from 'react';
import './Contacto.css';
import MapsLocation from './MapsLocation';
import bgContactoBasica from '../../assets/images/bg/bg-contacto-basica.jpg';
// import bgContactoMedia from '../../assets/images/bg/bg-contacto-media.jpg';


const Contacto = () => {
    // eslint-disable-next-line
    const [backgroundImage1, setBackgroundImage1] = useState(bgContactoBasica); 
    // eslint-disable-next-line
    const [backgroundImage2, setBackgroundImage2] = useState(bgContactoBasica); 

    return (
        <>
            {/* CONTAINER BASICA */}
            <div className="contacto-container" style={{ backgroundImage: `url(${backgroundImage1})` }}>
                <div className="contacto-content">
                    <h2 className="contacto-title">Enseñanza Básica</h2>
                    <p className="contacto-address">
                        Las Palmeras #958, Villa Mirador Del Pacífico<br />
                        San Antonio, Valparaíso.
                    </p>
                    <p className="contacto-phone">Teléfono: +56 9 8189 3038</p>
                    <a href="mailto:colegiofenix@colegiofenix.cl" className="contacto-email">colegiofenix@colegiofenix.cl</a>

                    <MapsLocation mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d209.52772340426282!2d-71.59956321661795!3d-33.59008096405454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x966238f478e7bcd5%3A0x9e62f0d158988914!2sColegio%20Fenix!5e0!3m2!1sen!2sus!4v1697568374619!5m2!1sen!2sus" />
                </div>
            </div>

            {/* CONTAINER MEDIA */}
            <div className="contacto-container" style={{ backgroundImage: `url(${backgroundImage2})` }}>
                <div className="contacto-content">
                    <h2 className="contacto-title">Enseñanza Media</h2>
                    <p className="contacto-address">
                        Isla de Pascua #1120, Villa Mirador Del Pacífico <br/>
                        San Antonio, Valparaíso.
                    </p>
                    <p className="contacto-phone">Teléfono: +56 9 8189 3021</p>
                    <a href="mailto:colegiofenix@colegiofenix.cl" className="contacto-email">colegiofenix@colegiofenix.cl</a>

                    <MapsLocation mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3353.9592754433584!2d-71.60893492357607!3d-33.59326097323795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x966238e9a9f6123f%3A0x51cb7eca531d193b!2sIsla%20de%20Pascua%201120%2C%20San%20Antonio%2C%20Valpara%C3%ADso%2C%20Chile!5e0!3m2!1sen!2sus!4v1697569812345!5m2!1sen!2sus" />
                </div>
            </div>
        </>
    );
};

export default Contacto;

import React from 'react';
import './Nosotros.css';
import ImagesBanner from './ImagesBanner';

const Nosotros = () => {
  // Dynamically import all images from the banner_nosotros folder
  const importAll = (r) => r.keys().map(r);
  const imageUrls = importAll(require.context('../../assets/images/banner_nosotros', false, /\.(png|jpe?g|svg)$/));

  return (
    <div className="nosotros-container">
      {/* Banner section */}
      <ImagesBanner images={imageUrls} />

      {/* Content section */}
      <p>
        Colegio Fenix, fundado el año 2003, es una institución educativa que se dedica a la formación integral de la persona, promoviendo el desarrollo de competencias y valores.
      </p>

      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/2ELO07Nlm3g"
          title="Colegio Fenix"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Nosotros;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './MainMenu.css';

const MainMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <div className='menu-icon' onClick={toggleMenu}>
        {/* Menu icon can be a simple div or an SVG icon */}
        <div className='menu-bar'></div>
        <div className='menu-bar'></div>
        <div className='menu-bar'></div>
      </div>

      <nav className={`side-menu ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li><Link to='/inicio' onClick={toggleMenu}>Inicio</Link></li>
          <li><Link to='/mision' onClick={toggleMenu}>Misión y Visión</Link></li>
          <li><Link to='/lirmi' onClick={toggleMenu}>Lirmi</Link></li>
          <li><Link to='/convivencia2024' onClick={toggleMenu}>Convivencia</Link></li>
          <li><Link to='/contacto' onClick={toggleMenu}>Contacto</Link></li>
          <li><Link to='/nosotros' onClick={toggleMenu}>Nosotros</Link></li>
          <li><Link to='/protocolos' onClick={toggleMenu}>Reglamentos y Protocolos</Link></li>
          <li><Link to='/noticias' onClick={toggleMenu}>Noticias</Link></li>
        </ul>
      </nav>

      {/* Overlay when menu is open */}
      {menuOpen && <div className='overlay' onClick={toggleMenu}></div>}
    </>
  );
};

export default MainMenu;

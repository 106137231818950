// ser/HomePage.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './HomePage.css';
import TopFold from './TopFold.js';
import TopLinks from './TopLinks.js';
import MainMenu from './MainMenu.js';
import Mision from './Pages/Mision.js';
import Lirmi from './Pages/Lirmi.js';
import Convivencia2024 from './Pages/Convivencia2024.js';
import Contacto from './Pages/Contacto.js';
import Nosotros from './Pages/Nosotros.js';
import Protocolos from './Pages/Protocolos.js';
import Noticias from './Pages/Noticias.js';
import NewsBanner from './Pages/NewsBanner.js';
import { noticiasData } from '../assets/data/Noticias_data.js';

const HomePage = () => {
    return (
        <div className="fenix-homepage">
            <TopLinks />
            <TopFold /> 

            <Router>
            <MainMenu />
            <div className='content'>
                <Routes>
                    <Route path='/inicio' element={<Nosotros />} />
                    <Route path='/mision' element={<Mision />} />
                    <Route path='/lirmi' element={<Lirmi />} />
                    <Route path='/convivencia2024' element={<Convivencia2024 />} />
                    <Route path='/contacto' element={<Contacto />} />
                    <Route path='/nosotros' element={<Nosotros />} />
                    <Route path='/protocolos' element={<Protocolos />} />
                    <Route path='/noticias' element={<Noticias />} />
                    {/* default route */}
                     <Route path='*' element={<Nosotros />} />
                </Routes>
            </div>
            <NewsBanner news={noticiasData} />
            </Router>

        </div>
    );
};
export default HomePage;

import React from 'react';
import './Lirmi.css';

const Lirmi = () => {
    return (
        <div className="lirmi-container">
            <h1 className="lirmi-title">ACCESO A LIRMI</h1>
            <p className="lirmi-description">
            Acceso a la plataforma &nbsp;
                <a href="https://www.lirmi.com/cl" target="_blank" rel="noopener noreferrer">Lirmi.</a>
            </p>
            <div className="lirmi-buttons">
                <a href="https://login.lirmi.com/login" target="_blank" rel="noopener noreferrer" className="lirmi-button">
                    Ingreso Profesores
                </a>
                <a href="https://lms.lirmi.com/login" target="_blank" rel="noopener noreferrer" className="lirmi-button">
                    Ingreso Estudiantes
                </a>
            </div>
        </div>
    );
};

export default Lirmi;

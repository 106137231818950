import React from 'react';
import './MapsLocation.css';

const MapsLocation = ({ mapSrc }) => {
    return (
        <div className="maps-container">
            <iframe
                title="Colegio Fenix Location"
                src={mapSrc}
                width="600"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>
    );
};

export default MapsLocation;

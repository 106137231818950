import React from 'react';
import './Protocolos.css';

const Protocolos = () => {
    const protocolos = [
        //OJO!! folder is public/assets/pdfs/protocols/ solo que 'public' no se escribe en el path.
        {
            name: 'Proyecto educativo Colegio Fenix',
            link: '/assets/pdfs/protocols/Proyecto_Educativo_Colegio_Fenix_2024.pdf'
        },
        {
            name: 'Reglamento interno colegio Fenix',
            link: '/assets/pdfs/protocols/Reglamento-Interno-Agenda-2024.pdf'
        },
        {
            name: 'Protocolo desaparición alumno al interior del colegio',
            link: '/assets/pdfs/protocols/PROTOCOLO_DESAPARICION_-ALUMNO-AL-INTERIOR-DEL-COLEGIO.pdf'
        },
        {
            name: 'Protocolo acoso escolar y bullying',
            link: '/assets/pdfs/protocols/PROTOCOLO_ACOSO-ESCOLAR_BULLING.pdf'
        },
        {
            name: 'Protocolo accidentes escolares',
            link: '/assets/pdfs/protocols/PROTOCOLO_ACCIDENTES-ESCOLARES.pdf'
        },
        {
            name: 'Protocolo situaciones de abuso sexual y hechos de connotación sexual',
            link: '/assets/pdfs/protocols/PROTOCOLO-SITUACIONES-DE-ABUSO-SEXUAL-Y-HECHOS-DE-CONNOTACION-SEXUAL_2022.pdf'
        },
        {
            name: 'Protocolo prevención y abordaje ciberbullying',
            link: '/assets/pdfs/protocols/PROTOCOLO-PREVENCION-Y-ABORDAJE_CIBERBULLYING.pdf'
        },
        {
            name: 'Protocolo maltrato',
            link: '/assets/pdfs/protocols/Protocolo-Maltrato-Modificado_Ciangarotti.pdf'
        },
        {
            name: 'Protocolo de salidas pedagógicas',
            link: '/assets/pdfs/protocols/PROTOCOLO-DE-SALIDAS-PEDAGOGICAS.pdf'
        },
        {
            name: 'Protocolo de retiro de celulares',
            link: '/assets/pdfs/protocols/PROTOCOLO-DE-RETIRO-DE-CELULARES-VIERNES-16-DE-JUNIO.pdf'
        },
        {
            name: 'Protocolo de emergencias en caso de terremoto',
            link: '/assets/pdfs/protocols/PROTOCOLO-DE-EMERGENCIAS-EN-CASO-DE-TERREMOTO.pdf'
        },
        {
            name: 'Protocolo alumnas embarazadas',
            link: '/assets/pdfs/protocols/PROTOCOLO-ALUMNAS-EMBARAZADAS.pdf'
        },
        {
            name: 'Protocolo vulneración de derechos',
            link: '/assets/pdfs/protocols/Protc_VULNERACION-DE-DERECHOS.pdf'
        },
        {
            name: 'Protocolo amenaza de atentado o tiroteo al interior del colegio',
            link: '/assets/pdfs/protocols/Reglamento-Interno-Agenda-2024.pdf'
        }
    ];
    

    return (
        <div className="protocolos-container">
            <h2 className="protocolos-title">Reglamentos y Protocolos</h2>
            <p>Procedimientos claros y específicos para abordar situaciones críticas o emergencias que pueden surgir en el entorno escolar. Estos protocolos garantizan que los estudiantes, profesores y personal administrativo estén preparados para responder de manera efectiva en caso de una emergencia, minimizando el riesgo de lesiones o daños.</p>
            <table className="protocolos-table">
                <thead>
                    <tr>
                        <th>Nombre del Protocolo</th>
                    </tr>
                </thead>
                <tbody>
                    {protocolos.map((protocolo, index) => (
                        <tr key={index}>
                            <td>
                                <a href={protocolo.link} target="_blank" rel="noreferrer noopener">
                                    {protocolo.name}
                                </a>
                            </td>
                           
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Protocolos;

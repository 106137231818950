// import logo from './logo.svg';
import './App.css'
// src/App.js
import React from 'react';
import HomePage from './Views/HomePage';

function App() {
  return (
    <div className="App">
      <HomePage />
    </div>

//TODO: MODIFICAR ESTO?
   
  );
}

export default App;

import React, { useEffect } from 'react';
import './TopFold.css';
import logoFenix512 from '../assets/images/logo512.png';

const TopFold = () => {
  useEffect(() => {
    const logo = document.querySelector('.logo');
    const maxRotation = 45; // Maximum rotation in degrees
    // const maxTranslate = 10; // Maximum translation in pixels

    const handleMouseMove = (e) => {
      const rect = logo.getBoundingClientRect();
      const x = e.clientX - rect.left; // X position within the logo
      const y = e.clientY - rect.top;  // Y position within the logo

      // Calculate rotation based on mouse position
      const rotateY = ((x / rect.width) - 0.5) * maxRotation * 2; // Rotate Y axis
      const rotateX = -((y / rect.height) - 0.5) * maxRotation * 2; // Rotate X axis

      // Apply transformation
      logo.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale(1.05)`;
      logo.style.filter = `drop-shadow(0px 15px 25px rgba(0, 0, 0, 0.3))`;
    };

    const handleMouseLeave = () => {
      // Reset transformation
      logo.style.transform = `perspective(1000px) rotateX(0deg) rotateY(0deg) scale(1)`;
      logo.style.filter = `drop-shadow(0px 15px 25px rgba(0, 0, 0, 0.3))`;
    };

    const handleMouseEnter = () => {
      // Optional: Enhance shadow or scale on mouse enter
      logo.style.transition = 'transform 0.3s ease, filter 0.3s ease';
    };

    const handleTouchMove = (e) => {
      e.preventDefault(); // Prevent scrolling while touching the logo
      const touch = e.touches[0];
      const rect = logo.getBoundingClientRect();
      const x = touch.clientX - rect.left;
      const y = touch.clientY - rect.top;

      const rotateY = ((x / rect.width) - 0.5) * maxRotation * 2;
      const rotateX = -((y / rect.height) - 0.5) * maxRotation * 2;

      logo.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale(1.05)`;
      logo.style.filter = `drop-shadow(0px 15px 25px rgba(0, 0, 0, 0.3))`;
    };

    const handleTouchEnd = () => {
      logo.style.transform = `perspective(1000px) rotateX(0deg) rotateY(0deg) scale(1)`;
      logo.style.filter = `drop-shadow(0px 15px 25px rgba(0, 0, 0, 0.3))`;
    };

    logo.addEventListener('mousemove', handleMouseMove);
    logo.addEventListener('mouseleave', handleMouseLeave);
    logo.addEventListener('mouseenter', handleMouseEnter);
    logo.addEventListener('touchmove', handleTouchMove);
    logo.addEventListener('touchend', handleTouchEnd);
    logo.addEventListener('touchcancel', handleTouchEnd);

    return () => {
      logo.removeEventListener('mousemove', handleMouseMove);
      logo.removeEventListener('mouseleave', handleMouseLeave);
      logo.removeEventListener('mouseenter', handleMouseEnter);
      logo.removeEventListener('touchmove', handleTouchMove);
      logo.removeEventListener('touchend', handleTouchEnd);
      logo.removeEventListener('touchcancel', handleTouchEnd);
    };
  }, []);

  return (
    <header className='header-container'>
      <div className='header-content'>
        <img src={logoFenix512} alt='Colegio Fenix Logo' className='logo' />
        <div className='header-text'>
          <h1>COLEGIO FENIX</h1>
          <p className="header-motto">EDUCAR, INSPIRAR, TRANSFORMAR</p>
        </div>
      </div>
    </header>
  );
};

export default TopFold;

export const noticiasData = [
    {
        title: "Financiamiento Compartido Trienio 2025",
        date: "28/10/2024",
        content: "A continuación, link de descarga a documento con información sobre Financiamiento Compartido 2025-2026-2027 para padres y apoderados.",
        pdf: "/assets/pdfs/trienios/TRIENIO_ 2025-2027.pdf"
    },
    {
        title: "¡Nueva página web!",
        date: "20/09/2024",
        content: "Contamos con una nueva página web pensada en ser un lugar de fácil acceso para padres, apoderados y estudiantes. Aquí encontrarás información relevante y actualizada sobre nuestro colegio.",
    },
    {
        title: "Nueva sección Noticias",
        date: "01/07/2024",
        content: "Nuestra nueva página web cuenta con una sección de noticias, donde podrás encontrar las últimas noticias de nuestro colegio.",
    }
];
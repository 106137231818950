import React from 'react';
import './Convivencia2024.css';
import limiteLoPongoYo from '../../assets/images/convivencia/limite_lo_pongo_yo.png';


const Convivencia2024 = () => {
  return (
    <div className="convivencia-container">
      <img src={limiteLoPongoYo} alt="Límite lo pongo yo" className="campaign-image" />
      {/* <h1>Campaña Convivencia Escolar 2024</h1> */}
      <p>
      La convivencia escolar es clave para construir una comunidad sana, donde cada estudiante se sienta seguro, valorado y apoyado. Luchar contra la depresión y los problemas de salud mental comienza con una palabra amable, una acción solidaria y un entorno que fomente el respeto y la empatía.
      </p>
      
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/Ogil3nK2nrk"
          title="Convivencia 2024 Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <p>
      Este video es un recordatorio de la importancia de apoyarnos unos a otros. Juntos podemos marcar la diferencia en la vida de quienes lo necesitan. Hagamos de nuestras escuelas un espacio donde todos puedan florecer emocional y académicamente.
      </p>
    </div>
  );
};

export default Convivencia2024;

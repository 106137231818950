import React from 'react';
import './Noticias.css';
import Noticia from './Noticia';
import { noticiasData } from '../../assets/data/Noticias_data.js';

const Noticias = () => {
    return (
        <div className="noticias-container">
            <h1 className="noticias-title">Noticias</h1>
            {noticiasData.map((noticia, index) => (
                <Noticia
                    key={index}
                    {...noticia}
                />
            ))}
        </div>
    );
};

export default Noticias;

import React, { useState, useEffect, useCallback } from 'react';
import './ImagesBanner.css';

const ImagesBanner = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showLargeImage, setShowLargeImage] = useState(false);

    // Memoize nextSlide to prevent unnecessary re-creations
    const nextSlide = useCallback(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, [images.length]);

    const prevSlide = useCallback(() => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    }, [images.length]);

    const handleImageClick = () => {
        setShowLargeImage(true);
    };

    const closeLargeImage = () => {
        setShowLargeImage(false);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide();
        }, 5000); // 5 seconds

        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, [nextSlide]); // Now only dependent on nextSlide

    return (
        <div className="banner-container">
            <div 
                className="banner-slide" 
                style={{ backgroundImage: `url(${images[currentIndex]})` }}
                onClick={handleImageClick}
            >
                {/* <div className="banner-overlay">
                    <h3 className="banner-subtitle">COLEGIO FENIX</h3>
                    <h1 className="banner-title">Tu educación es nuestra prioridad.</h1>
                    <p className="banner-description">Creemos en el potencial de cada estudiante.</p>
                </div> */}
            </div>
            <button className="banner-arrow left-arrow" onClick={prevSlide}>&#10094;</button>
            <button className="banner-arrow right-arrow" onClick={nextSlide}>&#10095;</button>
            
            {showLargeImage && (
                <LargeImageView 
                    image={images[currentIndex]} 
                    onClose={closeLargeImage}
                />
            )}
        </div>
    );
};

const LargeImageView = ({ image, onClose }) => {
    return (
        <div className="large-image-overlay" onClick={onClose}>
            <div className="large-image-container">
                <img src={image} alt="Large view" className="large-image" />
                <button className="close-button" onClick={onClose}>&times;</button>
            </div>
        </div>
    );
};

export default ImagesBanner;

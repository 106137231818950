import React from 'react';
import { Link } from 'react-router-dom';
import './NewsBanner.css';

const NewsBanner = ({ news }) => {
    // Only use each news item once and limit the number of items
    const newsString = news.slice(0, 5).map(item => 
        `${item.title} (${item.date})`
    ).join('   •   ');

    return (
        <div className="news-banner">
            <div className="news-banner-content">
                <Link to="/noticias" className="scrolling-text">
                    {newsString}
                </Link>
            </div>
        </div>
    );
};

export default NewsBanner;

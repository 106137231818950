import React, { useState } from 'react';
import './Noticia.css';

const Noticia = ({ title, date, content, image, video, pdf }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isImageEnlarged, setIsImageEnlarged] = useState(false);
    
    // Function to get preview text (first 150 characters)
    const getPreviewText = () => {
        return content.slice(0, 150) + '...';
    };

    const handleImageClick = () => {
        setIsImageEnlarged(true);
    };

    const handleOverlayClick = () => {
        setIsImageEnlarged(false);
    };

    return (
        <div className="noticia-container">
            <h2 className="noticia-title">{title}</h2>
            <p className="noticia-date">{date}</p>
            <div className="noticia-content">
                <p className="noticia-text">
                    {isExpanded ? content : getPreviewText()}
                </p>
                {!isExpanded && (
                    <button 
                        className="read-more-btn"
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        Leer más...
                    </button>
                )}
                {isExpanded && (
                    <>
                        {image && <img 
                            src={image} 
                            alt="Noticia" 
                            className="noticia-image" 
                            onClick={handleImageClick}
                        />}
                        {video && (
                            <div className="noticia-video">
                                <iframe
                                    width="560"
                                    height="315"
                                    src={video}
                                    title="Noticia Video"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        )}
                        {pdf && (
                            <div className="noticia-pdf">
                                <a 
                                    href={pdf}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="pdf-download-btn"
                                >
                                    Descargar PDF
                                </a>
                            </div>
                        )}
                        <button 
                            className="read-more-btn"
                            onClick={() => setIsExpanded(!isExpanded)}
                        >
                            Leer menos
                        </button>
                    </>
                )}
            </div>
            {isImageEnlarged && (
                <div className="image-overlay" onClick={handleOverlayClick}>
                    <img 
                        src={image} 
                        alt="Noticia" 
                        className="enlarged-image"
                    />
                </div>
            )}
        </div>
    );
};

export default Noticia;

import React from 'react';
import './Mision.css';

const Mision = () => {
  return (
    <div className='mision'>
      <h1>Nuestra Misión</h1>
      <p style={{ textAlign: 'justify' }}>
      En nuestra institución educativa, nos comprometemos con la formación integral de personas competentes, preparadas para integrarse en el mundo y afrontar los desafíos contemporáneos. Proveemos a nuestros estudiantes de los conocimientos, la disciplina, los valores y las habilidades necesarios para su pleno desarrollo y para fomentar el respeto por la legalidad vigente.
      </p>

      <p style={{ textAlign: 'justify' }}>
      Nuestra propuesta curricular integra de manera armoniosa lo académico con la cultura, las artes, el deporte y las competencias sociales y humanísticas. A través de esta integración, promovemos el aprendizaje integral y el ejercicio activo de la ciudadanía dentro del espacio escolar.
      </p>
      <br />

      <h1>Nuestra Visión</h1>
      <p style={{ textAlign: 'justify' }}>
      Aspiramos a consolidarnos como un proyecto educativo enfocado en la formación integral del ser humano, construido sobre la base de estructuras académicas y administrativas flexibles. Nos distinguimos por promover la integración del conocimiento, el trabajo interdisciplinario, la libertad y el desarrollo de la mente y el espíritu, todo ello dentro de un ambiente de justo rigor y disciplina.
      </p>
    </div>
  );
};

export default Mision;
